import { useAppStore } from '~/stores/app'

export default function () {
  const isLocked = useState<boolean>('bodyLock', () => false)
  const store = useAppStore()

  function bodyLock() {
    isLocked.value = true
    store.lenis.stop()
  }

  function bodyUnlock() {
    isLocked.value = false
    store.lenis.start()
  }

  return {
    bodyLock,
    bodyUnlock
  }
}
